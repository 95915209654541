import React from 'react';
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import video from './ressources/videoFond.mp4';
import Carousel from './Accueil/Carousel'
import CardMedia from '@material-ui/core/CardMedia';
import Link from "@material-ui/core/Link"
import PresentationTissuAccueil from './Accueil/PresentationTissuAccueil';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ScrollAnimation from 'react-animate-on-scroll';


const StyleTest = makeStyles({
    ContainerTop: {
        paddingTop: "64px",
        height: "100vh",
        zIndex: -15,
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        "@media screen and (max-width: 830px)" : {
            backgroundColor: "white",
            display: "none",
        }
    },
    couleur: {
        color: "white",
        marginLeft: "5%",
        marginRight: "5%",
        textAlign: "left",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        marginBottom: "0px",
        textTransform: "uppercase",
        fontWeight: "400",
        "@media screen and (max-width: 830px)" : {
            fontSize: "2em",
            textAlign: "center",
        }
    },
    video: {
        right: 0,
        top: 0,
        left: 0,
        height: "100%",
        width: "177.77777778vh",
        minWidth: "100%",
        minHeight: "56.25vw",
        zIndex: -10,
        position: "fixed",
        "@media screen and (max-width: 830px)" : {
          display: "none",
          gridTemplateRows: "33.3% 33.4% 33.3%",
      },
    },
    img:{
        width: "50%",
        right:0,
        position: "absolute",
        marginTop:"8%",
        zIndex: "1",
        "@media screen and (max-width: 830px)" : {
          position:"relative",
          width: "100%"
        },
    },
    fondTech:{
        paddingTop: "16%",
        marginLeft: "10%",
        marginRight: "10%",
        background: "white",
        opacity: "0.75",
        marginTop: "20%",
        transition: "opacity 0.5s",
        "&:hover": {
            opacity: "100%",
        },
        "@media screen and (max-width: 830px)" : {
            background: "none",
            paddingTop: "0",
            marginLeft: "2%",
            marginRight: "2%",
        }
    },
    titreTech:{
        textAlign: "left",
        fontSize: "5em",
        fontWeight: "100",
        lineHeight: "1.4",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        width: "100%",
        paddingBottom: "40px",
        paddingLeft: "5%",
        color :"#0E3B85",
    },
    texteTech:{
        paddingTop: "40px",
        color:"#0E3B85",
        fontSize: "2em",
        width: "70%",
        paddingLeft: "5%",
        "@media screen and (max-width: 830px)" : {
            width: "100%",
        }
    },
    barre:{
        width: "25%",
        marginLeft: "5%",
        border :"1.5px solid #0E3B85",
    },
    barreLien:{
        width: "25%",
        marginLeft: "5%",
        border :"1.5px solid white",
    },
    divTissuTech: {
      width: "60%",
      display: "flex",
      transition: "width 1s !important",
      background: "#0E3B85",
      color: "white",
      paddingLeft:"-5%",
      alignSelf: "center",
      fontSize: "2em",
      border: "none",
      "&:hover": {
        textDecoration: "none",
        cursor: "pointer",
        width: "100%",
        backgroundColor: "#0D2F66",
      },
      "@media screen and (max-width: 830px)" : {
        width: "100%",
      }
    },
    texteBarre: {
        width: "100%",
        backgroundColor: "none",
        transition: "width 1s",
    },
    divMain: {
        overflowX: "hidden",
        "@media screen and (max-width: 830px)" : {
            backgroundColor: "white",
        }
    },
    titreAccueil:{
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "100",
        textAlign: "center",
    },
    titreAccueilResponsive:{
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "100",
        color:"black",
        fontSize: "1.5rem",
        textAlign: "center",
        padding: "20px 0px",
    },
    AccueilResponsive:{
        display: "none",
        marginTop: "75px",
        "@media screen and (max-width: 830px)" : {
            display: "block",
        }
    },
    logo:{
        marginLeft: "auto",
        marginRight: "auto",
        width: "45%",
        "@media screen and (max-width: 830px)" : {
            width: "75%",
        }
    }
});

const Accueil = () => {
    const classes=StyleTest();
    return(
        <div className={classes.divMain}>
            <div>
            <main>
                <Container maxWidth="false" className={classes.ContainerTop} >
                    <CardMedia
                        className={classes.video}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        component="video"
                    >
                        <source src={video} type="video/mp4"/>
                    </CardMedia>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <CardMedia
                            component="img"
                            image={require(`./ressources/logoBlanc.png`)}
                            className={classes.logo}
                        />
                        <Typography variant="h4" className={classes.titreAccueil} component="h4" gutterBottom>
                          Fabricant et créateur de tissus d'exception.
                        </Typography>
                      </ScrollAnimation>
                </Container>
                <div className={classes.AccueilResponsive}>
                    <CardMedia
                        component="img"
                        image={require(`./ressources/logoNoir.png`)}
                        className={[classes.logo,"titreTop"]}
                    />
                    <Typography variant="h1" className={classes.titreAccueilResponsive} component="h2" gutterBottom>
                        Fabricant et créateur de tissus d'exception.
                    </Typography>
                </div>
                    {/*<Carousel/>*/}
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <div className="divSavoirFaire">
                            <Link href="/Notre-savoir-faire" className="texteBarre">
                                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className={classes.couleur}>
                                   <br/>
                                   Notre Savoir-faire
                                   <br/>
                                   <br/>
                                 </Typography>
                                 <Typography variant="h5" align="center" color="textSecondary" paragraph className={classes.couleur}>
                                  </Typography>
                            </Link>
                            <Link className="flecheTexte"  href="/Notre-savoir-faire">
                                    <ArrowForwardIcon className="fleche" style={{fontSize: "2rem"}}/>
                                    <Typography className="texteCache" variant="h5" align="center">Voir notre savoir-faire</Typography>
                            </Link>
                        </div>
                    </ScrollAnimation>
                <PresentationTissuAccueil/>
                
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </main>
                <br/>
                <br/>
                <br/>
                <br/>
                
            </div>
        </div>
    );
}
export default Accueil;
