import React from 'react';
import AppBar from '@material-ui/core/AppBar'
import Link from "@material-ui/core/Link"
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuIcon from '@material-ui/icons/Menu';
import ToolBar from '@material-ui/core/ToolBar'
import logoepv from './ressources/logoepv.png';
import imageepv from './ressources/imageepv.jpg';
import CardMedia from '@material-ui/core/CardMedia';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import DropDown from './NavBar/DropDown';
import ListMenu from './NavBar/ListMenu';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import {Menu} from '../variables.js';

const StyleTest = makeStyles((theme) => ({
    couleur: {
        color: "white",
        borderRight: "none",
        marginRight:"10px",
        alignSelf: "center",
        transition: "font-size 0.5s",
        "@media screen and (max-width: 830px)" : {
            color: "black",
        }
    },
    bordure: {
        background: "#0E3B85",
        boxShadow: 'none',
        height: "64px",
        paddingRight: "16px",
        paddingLeft: "16px",
        "@media screen and (max-width: 830px)" : {
            backgroundColor: "white",
            borderBottom: "solid 2px #0E3B85",
            boxShadow: "none",
        },
    },
    responsive: {
        "@media screen and (max-width: 830px)" : {
            display: "none",
        },
    },
    bouton: {
        opacity: 0,
        display: "none",
        width: "auto",
        right: "0",
        position: "absolute",
        color: "#0E3B85",
        "@media screen and (max-width: 830px)" : {
            display: "flex",
            opacity: 1,
        }
    },
    logo: {
        opacity: 1,
        width: "auto",
        height: "100%",
        right: "0",
        position: "absolute",
        color: "#0E3B85",
        "@media screen and (max-width: 830px)" : {
            display: "none",
            opacity: 0,
        },
        "&:hover":{
            display:"none",
            texteepv:{
                display:"none",
            },
        },
    },
      title: {
        flexGrow: 1,
      },
      hide: {
        display: 'none',
      },
      drawer: {
        flexShrink: 0,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-start',
        color: "white",
      },
      headerBar : {
        backgroundColor: "transparent",
        boxShadow: "none",
        transition: "transform 1s, opacity 1s",
      },
      lienResponsive : {
        color: "white",
        textDecoration: "none !important",
      },
      boutonResponsive: {
        color: "white",
        marginRight:"10px",
        alignSelf: "center",
        "&:hover": {
          textDecoration: "none",
        },
      },
      menuButton: {
        backgroundColor: "#0E3B85",
        color:"white",
      },
      texteepv :{
        padding: "20px",
        alignSelf: "center",
        textAlign: "center",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.25em",
        fontWeight: "300",
        color: "white",
        backgroundColor: "#0E3B85",
        height: "100%",
        marginBottom: 0,
      },
      imageepv:{
        width: "20% !important",
      }
}));

const NavBar = () => {
    const classes = StyleTest();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
    
    const itemsMenu = [];
    for (let i = 0; i < Menu.length; i++)
    {        
        const item = Menu[i];
        
        if(item.length!==2){
            const media = (
                <Link key={item.split(' ')} href={`/${item.split(' ').join('-')}`}  style={{borderRight: "none"}} className={classes.couleur}>
                    <Button key={Menu[i].split(' ')} className={classes.couleur} variant="text" size="small" edge="start">{item}</Button>
                </Link>
            )
            itemsMenu.push(media);
        }
        else{
            const media = (
                <DropDown key={item[0]} nom={item[0]} options={item[1]}/>
            )
            itemsMenu.push(media);
        }
    }
    return(
        <div>
            <CssBaseline/>
            <AppBar className={[classes.headerBar,"NavBar"]}>
                <ToolBar className={classes.bordure} varient="dense">
                    <Link href="/" className={classes.couleur}>
                      <Button className={classes.couleur} style={{width: "190px"}} size="small" edge="start">
                        Tissage des roziers
                      </Button>
                    </Link>
                    <ButtonGroup color="primary" className={classes.responsive} aria-label="text primary button group">
                        {itemsMenu}
                    </ButtonGroup>
                    <IconButton className={clsx(classes.menuButton,classes.couleur,classes.bouton, open && classes.hide)} aria-label="open drawer" onClick={handleDrawerOpen} size="small" variant="text" edge="start">
                        <MenuIcon/>
                    </IconButton>
                    <div className="divepv">
                        <CardMedia component="img" className="logoepv" image={logoepv}/>
                        <div className="texteepv">
                            <Typography className={classes.texteepv} variant="body1" gutterBottom>
                                Au sein des petites et moyennes entreprises françaises existe un large ensemble de savoir-­faire artisanaux et techniques d'excellence, qui sont souvent transmis de génération en génération.
                                Les pouvoirs publics ont souhaité, par le label "Entreprise du Patrimoine Vivant", promouvoir et développer ces petites et moyennes entreprises singulières, détenant un patrimoine économique composé en particulier d'un savoir-­faire rare, renommé ou ancestral et circonscrit à un territoire. Le label a été obtenu par Tissage des Roziers en février 2007.
                            </Typography>
                            <CardMedia component="img" className={["logoepv",classes.imageepv]} style={{position: "relative"}} image={imageepv}/>
                        </div>
                    </div>
                </ToolBar>
            </AppBar>
            
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
              >
                <div className={classes.drawerHeader}>
                  <IconButton style={{color: "white"}} onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon />}
                  </IconButton>
                  <Button className={classes.boutonResponsive} onClick={handleDrawerClose} variant="text" size="small" edge="start">Revenir à la page</Button>
                  
                </div>
                <Divider style={{backgroundColor: "white"}}/>
                <ListMenu/>
              </Drawer>
        </div>
    );
}
export default NavBar;