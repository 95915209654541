import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link"
import ScrollAnimation from 'react-animate-on-scroll';
import Typography from "@material-ui/core/Typography";
import {ListTissuAccueil} from '../../variables.js';

const useStyles =  makeStyles({
    couleurDroite: {
        color: "white",
        marginLeft: "5%",
        marginRight: "5%",
        textAlign:"left",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        "@media screen and (max-width: 830px)" : {
            fontSize: "1.5em",
        }
    },
    imgDroite:{
        width: "50%",
        right:0,
        position: "absolute",
        marginTop:"-12%",
        zIndex: "1",
        "@media screen and (max-width: 830px)" : {
          position:"relative",
          width: "100%",
          marginTop: "50px",
        },
    },
    fondTechDroite:{
        paddingTop: "16%",
        marginLeft: "10%",
        marginRight: "5%",
        background: "#FFFFFF",
        marginTop: "20%",
        opacity: "75%",
        transition: "opacity 0.5s",
        "&:hover": {
            opacity: "100%",
        },
        "@media screen and (max-width: 830px)" : {
            background: "none",
            paddingTop: "0",
            marginLeft: "2%",
            marginRight: "2%",
        }
    },
    titreTechDroite:{
        textAlign: "left",
        fontSize: "5em",
        fontWeight: "100",
        lineHeight: "1.4",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        width: "100%",
        paddingBottom: "40px",
        paddingLeft: "5%",
        color :"#0E3B85",
        "@media screen and (max-width: 830px)" : {
            fontSize: "3em",
        }
    },
    texteTechDroite:{
        paddingTop: "40px",
        color:"#0E3B85",
        fontSize: "2em",
        width: "90%",
        paddingLeft: "5%",
        "@media screen and (max-width: 830px)" : {
          width: "100%",
          fontSize: "1.5em",
        }
    },
    barreDroite:{
        width: "25%",
        marginLeft: "5%",
        border :"1.5px solid #0E3B85",
        backgroundColor: "#0E3B85",
        float: "left",
    },
    barreLienDroite:{
        width: "30%",
        marginLeft: "5%",
        border :"1.5px solid white",
        backgroundColor: "white",
    },
    divTissuTechDroite: {
      width: "60%",
      display: "flex",
      transition: "width 1s !important",
      background: "#0E3B85",
      color: "white",
      alignSelf: "center",
      fontSize: "2em",
      border: "none",
      "&:hover": {
        textDecoration: "none",
        cursor: "pointer",
        width: "100%",
        backgroundColor: "#0D2F66",
      },
      "@media screen and (max-width: 830px)" : {
        width: "100%",
      }
    },
    
    
    imgGauche:{
        width: "50%",
        left:0,
        position: "absolute",
        marginTop:"-12%",
        zIndex: "1",
        "@media screen and (max-width: 830px)" : {
          position:"relative",
          width: "100%",
          marginTop: "50px",
        },
    },
    couleurGauche: {
        color: "white",
        marginLeft: "5%",
        marginRight: "5%",
        textAlign:"right",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        "@media screen and (max-width: 830px)" : {
            fontSize: "1.5em",
        }
    },
    fondTechGauche:{
        paddingTop: "16%",
        marginLeft: "5%",
        marginRight: "10%",
        background: "#FFFFFF",
        marginTop: "20%",
        opacity: "75%",
        transition: "opacity 0.5s",
        "&:hover": {
            opacity: "100%",
        },
        "@media screen and (max-width: 830px)" : {
            background: "none",
            paddingTop: "0",
            marginLeft: "2%",
            marginRight: "2%",
        }
    },
    titreTechGauche:{
        textAlign: "right",
        fontSize: "5em",
        fontWeight: "100",
        lineHeight: "1.4",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        width: "100%",
        paddingBottom: "40px",
        paddingRight: "5%",
        color :"#0E3B85",
        "@media screen and (max-width: 830px)" : {
            fontSize: "3em",
        }
    },
    texteTechGauche:{
        paddingTop: "40px",
        color:"#0E3B85",
        fontSize: "2em",
        width: "90%",
        paddingRight: "5%",
        paddingBottom: "10px",
        clear:"right",
        float:"right",
        textAlign: "right",
        "@media screen and (max-width: 830px)" : {
            width: "100%",
            fontSize: "1.5em",
        }
    },
    barreGauche:{
        width: "25%",
        marginRight: "5%",
        border :"1.5px solid #0E3B85",
        backgroundColor: "#0E3B85",
        float: "right",
    },
    barreLienGauche:{
        width: "30%",
        marginRight: "5%",
        border :"1.5px solid white",
        backgroundColor: "white",
    },
    divTissuTechGauche: {
      width: "60%",
      display: "flex",
      transition: "width 1s, margin-left 1s",
      background: "#0E3B85",
      color: "white",
      alignSelf: "center",
      fontSize: "2em",
      border: "none",
      marginLeft: "40%",
      "&:hover": {
        textDecoration: "none",
        cursor: "pointer",
        width: "100%",
        backgroundColor: "#0D2F66",
        marginLeft: "0%",
      },
      "@media screen and (max-width: 830px)" : {
        width: "100%",
        marginLeft: 0,
      }
    },
    texteBarre: {
        width: "100%",
        backgroundColor: "none",
        transition: "width 1s",
    },
});


export default function PresentationTissuAccueil() {
  const classes = useStyles();
  let bool=true;
  function changeState(bool){
    return !bool;
  }

  const itemsMenu = [];
  for (let i = 0; i < ListTissuAccueil.length; i++)
    {        
        const item = ListTissuAccueil[i];
        const media = (
          <div>
            <ScrollAnimation animateIn="slideInUp" animateOnce={true} className={bool ? classes.imgDroite : classes.imgGauche }>
                    <CardMedia
                            component="img"
                            image={require(`../${ item[0] }`)}
                    />
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                    <div className={bool ? classes.fondTechDroite : classes.fondTechGauche}>
                            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className={bool ? classes.titreTechDroite : classes.titreTechGauche}>
                               { item[1] }
                             </Typography>
                        <hr className={bool ? classes.barreDroite : classes.barreGauche}/>
                        <div className={bool ? classes.texteTechDroite : classes.texteTechGauche}>
                            { item[2] }
                        </div>
                            <br/>
                            <div className={bool ? classes.divTissuTechDroite : classes.divTissuTechGauche}>
                                <Link href={ item[3] } className={classes.texteBarre}>
                                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className={bool ? classes.couleurDroite : classes.couleurGauche}>
                                       { item[4] }
                                       <hr className={bool ? classes.barreLienDroite : classes.barreLienGauche}/>
                                     </Typography>
                                </Link>
                            </div>
                    </div>
                </ScrollAnimation>
              </div>
            )
            itemsMenu.push(media);
            bool=changeState(bool);
        }
    
  
  
  return (
    <div>
      {itemsMenu}
    </div>
  );
}