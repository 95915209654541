import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ItemListTissuHauteCouture} from '../../variables.js';
import {ItemListTissuVelours} from '../../variables.js';
import CardMedia from "@material-ui/core/CardMedia";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from "@material-ui/core/Link";

const StyleTest = makeStyles({
    div: {
        marginTop: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    texte:{
        color: "Black",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "100",
        "@media screen and (max-width: 830px)" : {
            paddingTop: "20px",
            fontSize :"3em",
        },
    },
    photo:{
        margin: "50px",
        width: "80%",
        "@media screen and (max-width: 830px)" : {
            width: "90%",
            margin: "20px",
        },
    },
    breadcrumb:{
        marginRight: "auto",
        marginLeft: "5px",
    },
    texteDesc:{
        color: "Black",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "100",
        "@media screen and (max-width: 830px)" : {
            paddingTop: "20px",
            fontSize :"1.5em",
            textAlign: "center",
        },
    }
});

const TissuHauteCouture = (props) => {
    const classes=StyleTest();
    let list=[];
    let categ=props.match.params.categ;
    if(categ === "Haute-couture"){
        list=ItemListTissuHauteCouture;
    }
    else{
        list=ItemListTissuVelours;
    }
    const tissu=list.find(tissu => tissu.Id === props.match.params.tissu);
    return(
        <div className={classes.div}>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <Link color="inherit" href={`/${categ}`}>
              Tissus {categ.split('-').join(' ')}
            </Link>
            <Typography color="textPrimary">{tissu.NomTissu}</Typography>
          </Breadcrumbs>
            <Typography variant="h1" className={classes.texte}>
                {tissu.NomTissu}
            </Typography>
            
            <Typography variant="h5" className={classes.texteDesc}>
                {tissu.Description}
            </Typography>
            {tissu.Photos.map((photo) => 
                <CardMedia
                    component="img"
                    image={require( `../ressources/tissu/${ photo }` )}
                    className={classes.photo}
                />
            )}
        </div>
    );
}
export default TissuHauteCouture;
