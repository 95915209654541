import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import image from './ressources/francebleu.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginBottom: "50px",
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 165,
    "@media screen and (max-width: 830px)" : {
        width: "auto",
    },
  },
});
export default function MediaCard(props) {
  const classes = useStyles();
  const lien = props.lien;
  const nom = props.nom;
  const date = props.date;
  const source = props.source;
  
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {nom}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {date}
          </Typography>
        </CardContent>
        <div>
          <audio controls controlsList="nodownload" style={{marginRight: "5px"}}>
            <source src={lien} type="audio/mpeg"/>
            Votre navigateur ne supporte pas les éléments audios.
          </audio>
        </div>
        <Typography variant="subtitle1" color="textSecondary" style={{marginLeft: "5px"}}>
            Source : <a rel="noopener noreferrer" target="_blank" href={source}>France Bleu</a>
        </Typography>
      </div>
      <CardMedia
        className={classes.cover}
        image={image}
        title="France bleu"
      />
    </Card>
  );
}