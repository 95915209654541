import React, {Component} from 'react';
import { BrowserRouter as Router , Switch, Route} from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Accueil from './components/Accueil';
import Presentation from './components/Presentation';
import Contact from './components/Contact';
import SavoirFaire from './components/SavoirFaire';
import Medias from './components/Medias';
import Erreur from './components/Erreur';
import MentionsLegales from './components/MentionsLegales';
import ListTissu from './components/ListTissu';
import VelourVerre from './components/TissuTech/VelourVerre';
import Rideau from './components/TissuTech/Rideau';
import DetailTissu from './components/Tissu/DetailTissu';
import TissusTechniques from './components/TissusTechniques';
import './App.scss';
import "animate.css/animate.min.css";

class App extends Component {
  render() {
  return(
      <Router>
          <div>
            <NavBar />
              <Switch>
                <Route path="/" exact component={Accueil}/>
                <Route path="/Présentation" component={Presentation} />
                <Route path="/Techniques" component={TissusTechniques} />
                <Route path="/Médias" component={Medias} />
                <Route path="/Notre-savoir-faire" component={SavoirFaire} />
                <Route path="/Haute-couture" component={ListTissu} />
                <Route path="/Velours" component={ListTissu} />
                <Route path="/VelourVerre" component={VelourVerre} />
                <Route path="/RideauThermique" component={Rideau} />
                <Route path="/Contact" component={Contact} />
                <Route path="/MentionsLegales" component={MentionsLegales} />
                <Route path="/Tissus/:categ/:tissu" component ={DetailTissu} />
                <Route path="/:poubelle" component={Erreur} />
                {/*pour poubelle, faire un component erreur*/}
              </Switch>
            <Footer />
          </div>
      </Router>
    );}
}
  



export default App;