import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import DropDownDrawer from './DropDownDrawer';
import {Menu} from '../../variables.js';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  boutonClassique: {
    color: "white",
    width: "100%",
    borderRight: "none",
    marginRight:"10px",
    transition: "font-size 0.5s",
    "&:hover": {
      textDecoration: "none",
      fontSize:"0.9rem",
    },
  },
  logo:{
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "20px",
      width: "70%",
  }
}));

export default function ListMenu() {
  const classes = useStyles();

  const itemsMenu = [];
  for (let i = 0; i < Menu.length; i++)
  {        
        const item = Menu[i];
        
        if(item.length!==2){
            const media = (
              <div key={Menu[i].split(' ')}>
                <Link key={item.split(' ')} href={`/${item.split(' ').join('-')}`}>
                  <Button className={classes.boutonClassique}>
                    {item}
                  </Button>
                </Link>
                <Divider style={{backgroundColor: "white"}}/>
              </div>
            )
            itemsMenu.push(media);
        }
        else{
            const media = (
              <div key={item[0]}>
                <DropDownDrawer nom={item[0]} options={item[1]}/>
                <Divider style={{backgroundColor: "white"}}/>
              </div>
            )
            itemsMenu.push(media);
        }
  }
  
  
  return (
    <List component="nav" style={{padding: "0px"}}>
      <div>
        <Link href="/">
          <Button className={classes.boutonClassique}>
            Tissage des roziers
          </Button>
        </Link>
        <Divider style={{backgroundColor: "white"}}/>
      </div>
      {itemsMenu}
      <CardMedia
         component="img"
         image={require(`../ressources/logoBlanc.png`)}
         className={classes.logo}
      />
    </List>
  );
}