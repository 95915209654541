import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles =  makeStyles({
    titreTexte:{
      padding: "20px 0px",
    }
});


export default function Rideau() {
  const classes = useStyles();
  return (
    <div className={classes.div}>
        
    </div>
  );
}