import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import imageTissu from '../ressources/velourVerre2.jpg';
import CardMedia from '@material-ui/core/CardMedia';
import bg from '../ressources/velourVerre.jpg';

const useStyles =  makeStyles({
    containerHaut: {
        fontSize: "1.25em",
        fontWeight: "100",
        width: "100%",
        marginLeft: "20px",
        marginTop: "20px",
        float: "right",
        textAlign:"left",
        display: "flex",
        transition: "width 1s",
        alignSelf: "center",
        "@media screen and (max-width: 830px)" : {
            marginLeft:"0px !important",
            marginRight: "0px",
            padding: "20px",
            width: "100%",
            fontSize: "1.75em",
        },
    },
    titre: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        background: "linear-gradient(to left, #f44336,#f44336)",
        height: "300px",
        transform: "rotate(-15deg)",
        width: "800px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        color: "white",
        fontSize: "5em",
        fontWeight: "100",
        marginTop:"-70px",
        marginLeft:"-30px",
        transition: "width 1s",
        "@media screen and (max-width: 830px)" : {
            marginLeft:0,
            transform: "none",
            marginTop:"-150px",
            width: "100% !important",
            fontSize: "2em !important",
        },
        "@media screen and (max-width: 1200px)" : {
            width: "600px",
            fontSize: "3em",
        },
    },
    titreGauche: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        color: "white",
        fontSize: "5em",
        fontWeight: "400",
        textTransform: "uppercase",
        "@media screen and (max-width: 830px)" : {
            fontSize: "3em",
        },
    },
    divTexteActivite: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.5em",
        fontWeight: "100",
        padding: "5%",
        color: "black",
        textAlign: "justify",
        "@media screen and (max-width: 830px)" : {
            fontSize: "1em",
        },
    },
    divLettreActivite: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "10em",
        fontWeight: "300",
        height: "50%",
        lineHeight: "70%",
        color: "#0E3B85",
        "@media screen and (max-width: 830px)" : {
            fontSize: "1.25em",
        }
    },
    image: {
        width: "60%",
        alignSelf: "center",
        textAlign: "center",
        paddingBottom: "50px",
        "@media screen and (max-width: 830px)" : {
            width: "90%",
        }
    },
    imageDroit: {
        width: "40%",
        margin: "20px",
        padding:"5%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media screen and (max-width: 830px)" : {
            marginTop: "0px",
            paddingBottom: "40px",
            width: "90%",
        }
    },
    divHaut: {
        display: "flex",
        flexDirection: "column",
        "&::before":{
          opacity: 0.25,
          background:`url(${bg}) no-repeat center center`,
          content: "''",
          position: "fixed",
          width: "100%",
          height:"100%",
          backgroundSize: "cover",
          zIndex: -1,
          top: 0,
        }
    },
    texteImage: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.5em",
        fontWeight: "100",
        color: "black",
        "@media screen and (max-width: 830px)" : {
            marginTop: "10px",
            textAlign: "center",
            width: "80%",
        }
    },
    texteImageDroit: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.5em",
        width: "80%",
        fontWeight: "100",
        color: "black",
        textAlign: "center",
        "@media screen and (max-width: 830px)" : {
            marginTop: "10px",
            textAlign: "center",
            width: "80%",
        }
    },
    paragraphe: {
        textIndent: "50px",
        marginTop: "-20px",
    },
    hautBackground:{
      background : "linear-gradient(to top right,#f44336 50%,transparent 50%)",
      width: "100%",
      height: "150px",
    },
    milieuBackground:{
      backgroundColor: "#f44336",
      width: "100%",
      height: "150px",
      display: "flex",
      alignItems: "center",
      marginTop: "-1px",
      marginBottom: "-1px",
      justifyContent: "center",
    },
    basBackground:{
      background : "linear-gradient(to bottom left,#f44336 50%,transparent 50%)",
      width: "100%",
      height: "150px",
    },
    container:{
        display:"flex",
        flexWrap: "wrap",
        justifyContent: "center",
        "@media screen and (max-width: 830px)" : {
            flexDirection: "column",
        }
    },
    containerItem:{
        width: "750px",
        padding: "1%",
        "@media screen and (max-width: 830px)" : {
            width: "90%",
            alignSelf: "center",
        }
    },
    mediaCard:{
        background: "none",
        marginTop:"50px",
        boxShadow: "none",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});



export default function SavoirFaire() {
  const classes = useStyles();
  let items=[];
  for(let i=1;i<9;i++){
    let media=(
        <ScrollAnimation animateIn="slideInUp" className={classes.containerItem} animateOnce={true}>
            <CardMedia image={require( `../ressources/treffler/doc${i}.jpg`)} component="img" />
        </ScrollAnimation>
    );
    items.push(media);
  } 
  
  return (
    <div>
        <ScrollAnimation animateIn="rotateInDownLeft" animateOnce={true}>
            <div className={classes.titre}>
                Velours de verre
            </div>
        </ScrollAnimation>
           
        <div className={classes.divHaut}>
            <ScrollAnimation className={classes.containerHaut} animateIn="slideInUp" animateOnce={true}>
                <div className={classes.divTexteActivite}>
                    <p>
                        Le Velver est un tissu en velours de fils de verre ou de quartz qui permet d'allier les qualités de résistance mécanique élévée du tissu et l'épaisseur et la surface spécifique conséquente du velours avec les caractéristiques spécifiques du verre ou du quartz comme la tenue au feu pour l'isolation thermique, les compatibilités chimiques très larges en filtration (eau de process ou gaz industriels) ou encore l'accroche facilitée de dépot de photocatalyseur de type TiO2.
                    </p>
                    <p className={classes.paragraphe}>
                        Il est proposé dans des formes adaptées sur mesure par le façonnage et ses couts de production de tissage sont très compétitifs. Le tissu breveté Velver est totalement recyclable pour une réutilisation
                    </p>
                    <p className={classes.paragraphe}>
                        Les applications de ce nouveau tissu de type velours sont multiples : isolation thermique (habillement, aéronef, aviation, matériel militaire) ; média filtrant (air, eau, gaz) ; média catalytique ou photocatylique (support TiO2,...) ; matériaux composites.
                    </p>
                </div>
            </ScrollAnimation>
            <ScrollAnimation className={classes.image} animateIn="slideInUp" animateOnce={true}>
                   <span className={classes.texteImage}>Photo d'un échantillon de velour de verre.</span>
                    <CardMedia
                        image={imageTissu}
                        component="img"
                   />
            </ScrollAnimation>
        </div>
        
        
        <div className={classes.hautBackground}/>
        <div className={classes.milieuBackground}>
          <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
              <div className={classes.titreGauche}>
                  Documentation
              </div>
          </ScrollAnimation>
        </div>
        <div className={classes.basBackground}/>
        <div className={classes.container}>
                {items}
        </div>
    </div>
  );
}