import React from 'react';
import Carousel from "react-material-ui-carousel"
import Link from "@material-ui/core/Link"
import autoBind from "auto-bind"
import {makeStyles} from '@material-ui/core/styles';
import {itemsCarousel} from '../../variables.js';


import { Card, CardContent, CardMedia, Typography, Grid, Button} from '@material-ui/core';

const Style = makeStyles({
    taille: {
      "@media screen and (max-width: 830px)" : {
          maxWidth: "100%",
          flexBasis: "auto",
      },
    },
    link: {
      "&:hover": {
        textDecoration: "none",
      }
    },
});

function Banner(props)
{
    if (props.newProp) console.log(props.newProp)
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 3;
    const mediaLength = totalItems - 1;
    const classes = Style();
    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} className={classes.taille} key="content">
          <Link href={props.item.Lien}  className={["Link",classes.link]}> {/*href={`/${props.item.Id}`}*/}
            <CardContent className="Content">
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

                <Button variant="outlined" className="ViewButton">
                    {props.item.Message}
                </Button>
            </CardContent>
          </Link>
        </Grid>
    )

    
    for (let i = 0; i < mediaLength; i++)
    {
        const item = props.item.Items[i];
        const media = (
            <Grid className={[classes.taille]} item xs={12 / totalItems} key={item.Name}>
                 <Link href={item.Lien} className="Link">
                    <CardMedia
                        className="Media"
                        image={require( `../${ item.Image }` )}
                        title={item.Name}
                    >
                        <Typography style={{height: "auto"}} className={"MediaCaption"}>
                            {item.Name}
                        </Typography>
                    </CardMedia>
                </Link> 
                
            </Grid>
        )

        items.push(media);
    }

    if (contentPosition === "left")
    {
        items.unshift(content);
    }
    else if (contentPosition === "right")
    {
        items.push(content);
    }
    else if (contentPosition === "middle")
    {
        items.splice(items.length / 2, 0, content);
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className={"BannerGrid"}>
                {items}
            </Grid>
        </Card>
    )
}

class BannerExample extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            autoPlay: true,
            timer: 1,
            animation: "slide",
            indicators: true
        }

        autoBind(this);
    }

    toggleAutoPlay()
    {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators()
    {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    changeAnimation(event)
    {
        this.setState({
            animation: event.target.value
        })
    }

    render()
    {
        return (
            <div style={{ color: "#494949"}}>
              <div id="divCarousel">
    
                            
                <Carousel 
                    autoPlay={this.state.autoPlay}
                    timer={this.state.timer}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    
                >
                    {
                        itemsCarousel.map( (item, index) => {
                            return <Banner item={item} key={index} contentPosition={item.contentPosition}/>
                        })
                    }
                </Carousel>
                </div>
            </div>
        )
    }
}
export default BannerExample;