import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import imageTissu from './ressources/imageTissuSavoirFaire.png';
import imageEpv from './ressources/imageepv.jpg';
import CardMedia from '@material-ui/core/CardMedia';
import Link from "@material-ui/core/Link";
import bg from './ressources/background1.jpg';

const useStyles =  makeStyles({
    containerHaut: {
        fontSize: "1.5em",
        fontWeight: "100",
        width: "50%",
        marginRight: "20px",
        float: "right",
        textAlign:"left",
        display: "flex",
        transition: "width 1s",
        alignSelf: "center",
        "@media screen and (max-width: 830px)" : {
            marginLeft:"0px !important",
            marginRight: "0px",
            padding: "20px",
            width: "100%",
            fontSize: "1.75em",
        },
    },
    titre: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        background: "linear-gradient(to left, #f44336,#f44336)",
        height: "300px",
        transform: "rotate(-15deg)",
        width: "800px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        color: "white",
        fontSize: "5em",
        fontWeight: "100",
        marginTop:"-70px",
        marginLeft:"-30px",
        transition: "width 1s",
        "@media screen and (max-width: 830px)" : {
            marginLeft:0,
            transform: "none",
            marginTop:"-150px",
            width: "100% !important",
            fontSize: "2em !important",
        },
        "@media screen and (max-width: 1200px)" : {
            width: "600px",
            fontSize: "3em",
        },
    },
    titreGauche: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        color: "white",
        fontSize: "5em",
        fontWeight: "400",
        marginLeft:"15px",
        textTransform: "uppercase",
        "@media screen and (max-width: 830px)" : {
            fontSize: "3.5em",
        },
    },
    divTexteActivite: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.15em",
        fontWeight: "100",
        color: "black",
        textAlign: "justify",
        "@media screen and (max-width: 830px)" : {
            fontSize: "1em",
        },
        "&:first-letter":{
            color: "#0E3B85",
            fontSize: "7em",
            lineHeight: "80%",
            marginLeft:"-10px",
            marginTop:"-10px",
            float: "left",
            "@media screen and (max-width: 830px)" : {
                marginTop:"10px",
                marginBottom:"-30px",
            },
        }
    },
    divLettreActivite: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "10em",
        fontWeight: "300",
        height: "50%",
        lineHeight: "70%",
        color: "#0E3B85",
        "@media screen and (max-width: 830px)" : {
            fontSize: "1.25em",
        }
    },
    image: {
        width: "60%",
        margin: "20px",
        padding:"5%",
        alignSelf: "center",
        "@media screen and (max-width: 830px)" : {
            marginTop: "0px",
            paddingBottom: "40px",
            width: "90%",
        }
    },
    imageDroit: {
        width: "40%",
        margin: "20px",
        padding:"5%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media screen and (max-width: 830px)" : {
            marginTop: "0px",
            paddingBottom: "40px",
            width: "90%",
        }
    },
    divHaut: {
        display: "flex",
        marginTop:"50px",
        "@media screen and (max-width: 830px)" : {
            marginTop:"0px",
            flexDirection: "column-reverse",
        },
        "&::before":{
          opacity: 0.25,
          background:`url(${bg}) no-repeat center center`,
          content: "''",
          position: "fixed",
          width: "100%",
          height:"100%",
          backgroundSize: "cover",
          zIndex: -1,
          top: 0,
        }
    },
    texteImage: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.5em",
        width: "25%",
        fontWeight: "100",
        color: "black",
        marginTop: "-60px",
        position:"absolute",
        "@media screen and (max-width: 830px)" : {
            marginTop: "10px",
            textAlign: "center",
            width: "80%",
        }
    },
    texteImageDroit: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.5em",
        width: "80%",
        fontWeight: "100",
        color: "black",
        textAlign: "center",
        "@media screen and (max-width: 830px)" : {
            marginTop: "10px",
            textAlign: "center",
            width: "80%",
        }
    },
    paragraphe: {
        textIndent: "50px",
        marginTop: "-20px",
    },
    hautBackground:{
      background : "linear-gradient(to top right,#f44336 50%,transparent 50%)",
      width: "100%",
      height: "150px",
    },
    milieuBackground:{
      backgroundColor: "#f44336",
      width: "100%",
      height: "150px",
      display: "flex",
      alignItems: "center",
      marginTop: "-1px",
      marginBottom: "-1px",
      justifyContent: "center",
    },
    basBackground:{
      background : "linear-gradient(to bottom left,#f44336 50%,transparent 50%)",
      width: "100%",
      height: "150px",
    },
});


export default function SavoirFaire() {
  const classes = useStyles();
  return (
    <div>
        <ScrollAnimation animateIn="rotateInDownLeft" animateOnce={true}>
            <div className={classes.titre}>
                Notre savoir-faire
            </div>
        </ScrollAnimation>
           
        <div className={classes.divHaut}>
            <ScrollAnimation className={classes.image} animateIn="slideInUp" animateOnce={true}>
                    <CardMedia
                        image={imageTissu}
                        component="img"
                   />
                   <span className={classes.texteImage}>Photos tirées du film disponible <Link href="/Présentation" style={{borderBottom: "solid 1px black",color:"black"}}>ici</Link></span>
            </ScrollAnimation>
            <ScrollAnimation className={classes.containerHaut} animateIn="slideInUp" animateOnce={true}>
                <div className={classes.divTexteActivite}>
                    <p>
                        Une fabrication de tissu jacquard haut de gamme, velours jacquard et de tissus d’habillement d’exception tels que des laqués, poches, damassés, uni, faux unis, tweed, lancés découpés, jacquards lamés et tissus gaze.
                    </p>
                    <p className={classes.paragraphe}>
                        La société Tissage des Roziers travaille pour les plus grands noms de la Haute Couture française et étrangère.
                    </p>
                    <p className={classes.paragraphe}>
                        Spécialisée dans le tissage Jacquard elle utilise toutes les techniques de croisement des fils. Elle peut concevoir d'innombrables sortes de tissus damassés, matelassés, poches, lancé découpé, velours Jacquard. 
                    </p>
                    <p className={classes.paragraphe}>
                        Grâce à de nombreuses archives et essais réalisés en 50 ans d'activité, la société peut répondre très rapidement à des demandes spécifiques à partir de tous documents fournis.
                    </p>
                    <p className={classes.paragraphe}>
                        Le velours Jacquard tissé sur métiers BERIDOT, les tissus simple corps peuvent réaliser des dessins sur toute la largeur (120 cm maximum) et de hauteur illimitée. 
                    </p>
                    <p className={classes.paragraphe}>
                        Les tissus Jacquard réalisés uniquement sur métiers Dornier équipés de mécaniques Jacquard Staubli dans des largeurs de 150 sont destinés au Prêt à Porter de luxe et à la Haute Couture. Les tissus Gaze : réalisation de vraie Gaze pour des tissus destinés à la décoration ou à certains usages techniques et décoratifs.
                   </p>
                </div>
            </ScrollAnimation>
        </div>
        
        
        <div className={classes.hautBackground}/>
        <div className={classes.milieuBackground}>
          <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
              <div className={classes.titreGauche}>
                  Stratégie de l'entreprise
              </div>
          </ScrollAnimation>
        </div>
        <div className={classes.basBackground}/>
        
        <div className={classes.divHaut}>
            <ScrollAnimation className={classes.containerHaut} style={{marginLeft: "20px"}} animateIn="slideInUp" animateOnce={true}>
                <div className={classes.divTexteActivite}>
                    <p>
                        Comme indiqué plus haut, la société Tissage des Roziers agit dans le domaine de la fabrication de tissus pour la Haute Couture en petites et moyennes séries. Ce positionnement l’oblige à la recherche permanente afin de soutenir l’activité de la société.
                    </p>
                    <p className={classes.paragraphe}>
                        La société Tissage des Roziers doit aussi mettre en œuvre toutes les évolutions tant techniques qu’organisationnelles de façon à suivre la propre évolution de ses clients, leur donner entière satisfaction et développer son marché tout en restant dans son positionnement stratégique.
                    </p>
                    <p className={classes.paragraphe}>
                        Afin de développer son chiffre d’affaire, la société tente de développer de nouveaux produits pouvant être fabriqués par elle-même tout en restant dans son domaine de connaissances et dans des marchés dit de « niche » à forte valeur ajoutée. 
                    </p>
                </div>
            </ScrollAnimation>
            <ScrollAnimation className={classes.imageDroit} animateIn="slideInUp" animateOnce={true}>
                    <CardMedia
                        image={imageEpv}
                        component="img"
                   />
                   <span className={classes.texteImageDroit}>L'entreprise a recemment été promu Entreprise du Patrimoine Vivant</span>
            </ScrollAnimation>
        </div>
    </div>
  );
}