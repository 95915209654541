import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Histoire} from '../variables.js';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = theme => ({
    navBorder: {
        position: "fixed",
        borderRight: "2px solid #0E3B85",
        height: "400px",
        left: "10%",
        transition : "height 1s",
        zIndex: -1,
        "@media screen and (max-width: 830px)" : {
            display: "none",
        },
    },
    ulBorder : {
        marginTop: 0,
        position: "relative",
        listStyle:"none",
        paddingLeft: 0,
        width: "100%",
        top: 0,
        zIndex: 1,
    },
    divBorder : {
        border: "1.25px solid lightblue",
        backgroundColor: "lightblue",
        position: "fixed",
        left: "10%",
        bottom: 0,
        width: "2px",
        height: "100%",
        listStyle:"none",
        zIndex: -7,
        "@media screen and (max-width: 830px)" : {
            display: "none",
        },
    },
    top:{
        overflow: "auto",
        backgroundColor:"#fafafa",
        display: "block",
        zIndex: 1,
    },
    Film: {
        marginLeft: "25%",
        "@media screen and (max-width: 830px)" : {
            marginLeft: "0%",
            width: "100%",
        },
    },
    titre: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        background: "linear-gradient(to left, #f44336,#f44336)",
        height: "300px",
        transform: "rotate(-15deg)",
        width: "800px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        color: "white",
        fontSize: "5em",
        fontWeight: "100",
        marginTop:"-70px",
        marginLeft:"-30px",
        marginBottom:"50px",
        transition: "width 1s",
        "@media screen and (max-width: 830px)" : {
            marginLeft:0,
            transform: "none",
            marginTop:"-150px",
            width: "100% !important",
            fontSize: "2em !important",
        },
        "@media screen and (max-width: 1200px)" : {
            width: "600px",
            fontSize: "3em",
        },
    },
    
});

class Presentation extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            active : false,
            itemsTemp: [],
            items: [],
        }
        this.setOuvert = this.setOuvert.bind(this);
        this.setCache = this.setCache.bind(this);
        //pour supprimer l'avertissement de i non utilisé
        // eslint-disable-next-line
        for (const [i,value] of Histoire.entries()) {
            const item = value;
            const media = (
                <li key={item[0]} className={`itemCache`}>
                    <div className={`liBG`}>
                        {item[0]}
                    </div>
                    <div className={`liTexte`}>
                        <h2 className={`titre`}>
                            {item[1]}
                        </h2>
                    <div className={`titre ligne`}></div>
                            <br/>
                            <br/>
                        {item[2]}
                    </div>
                </li>
            )
            this.state.itemsTemp.push(media);
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    setOuvert(i){
        const item = Histoire[i];
        const tempItems=this.state.itemsTemp;
        tempItems[i] = (
            <li key={item[0]} className={`itemOuvert`}>
                <div className={`liBG`}>
                    {item[0]}
                </div>
                <div className={`liTexte`}>
                    <h2 className={`titre`}>
                        {item[1]}
                    </h2>
                    <div className={`titre ligne`}></div>
                        <br/>
                        <br/>
                    {item[2]}
                </div>
            </li>
        );
        this.setState({
            itemsTemp: tempItems
        });
        this.forceUpdate();
    }
    
    setCache(i){
        const item = Histoire[i];
        const tempItems=this.state.itemsTemp;
        tempItems[i] = (
            <li key={item[0]} className={`itemCache`}>
                <div className={`liBG`}>
                    {item[0]}
                </div>
                <div className={`liTexte`}>
                    <h2 className={`titre`}>
                        {item[1]}
                    </h2>
                    <div className={`titre ligne`}></div>
                    <br/>
                    <br/>
                    {item[2]}
                </div>
            </li>
        );
        this.setState({
            itemsTemp: tempItems
        });
        this.forceUpdate();
    }
    
    handleScroll = () => {
        var scroll=400;
        for (let i = 0; i < Histoire.length; i++) {
            if(window.scrollY>scroll){
                this.setOuvert(i);
                if(window.pageYOffset + window.innerHeight > 4150) {
                    document.getElementById("test").style.height="100%";
                    scroll=0;
                }
                else{
                    document.getElementById("test").style.height="400px";
                }
            }
            else{
                this.setCache(i);
            }
            scroll=scroll+275;
        }
    }
    
    render(){
        const { classes } = this.props;
        const items=this.state.itemsTemp;
        return (
        <div>
            <nav id="test" className={classes.navBorder} />
            <div>
                <div className={classes.top} >
                    <ScrollAnimation animateIn="rotateInDownLeft" animateOnce={true}>
                        <div className={classes.titre}>
                            Présentation
                        </div>
                    </ScrollAnimation>
                    <iframe width="45%" className={classes.Film} title="Film Entreprise" height="400" src="https://www.youtube.com/embed/9W_NGqIBEhY" frameborder="0" allowfullscreen="allowfullscreen">
                    </iframe>
                    <br/>
                    <h2 className={`titre titrePage`}>
                        Histoire de notre entreprise
                    </h2>
                </div>
                <ul className={classes.ulBorder}>
                    {items}
                </ul>
                <div className={classes.divBorder}>
                </div>
            </div>
        </div>
    );
    }
}

export default withStyles(useStyles)(Presentation)