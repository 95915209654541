import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import velourVerre from './ressources/velourVerre.jpg';


const StyleTest = makeStyles({
    div: {
        marginTop: "64px",
        display: "flex",
        background: "#0E3B85",
        "@media screen and (max-width: 830px)" : {
            flexDirection: "column",
        }
    },
    image:{
        background:`url(${velourVerre}) center`,
        marginLeft: "20%",
        width: "120%",
        height: "100%",
        transform: "skew(30deg)",
        backgroundSize: "cover",
        "@media screen and (max-width: 830px)" : {
            transform: "skew(0deg)",
            marginLeft: 0,
            width: "100%",  
        }
    },
    imageDroite:{
        //background:`url(${couture}) center`,
        backgroundColor: "black",
        color: "white",
        marginLeft: "-35%",
        width: "120%",
        height: "100%",
        transform: "skew(30deg)",
        backgroundSize: "cover",
        "@media screen and (max-width: 830px)" : {
            transform: "skew(0deg)",
            marginLeft: 0,
            width: "100%",
        }
    },
});

const TissusTechniques = () => {
    const classes=StyleTest();
    return(
        <div className={classes.div}>
                <div className="gaucheTissuTech">
                    <div className={classes.image}>
                        <Link href="/VelourVerre">
                            <Typography variant="h2" className="titreTissuTech">
                                Velours de verre
                            </Typography>
                        </Link>
                    </div>
                </div>
            <div className="droiteTissuTech">
                <div className={classes.imageDroite}>
                    <Link href="/RideauThermique">
                        <Typography variant="h2" className="titreDroiteTissuTech">
                            Rideaux thermiques<br/>
                        </Typography>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default TissusTechniques;
