import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const StyleTest = makeStyles({
    div: {
        height: "250px",
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        transition: "width 1s",
        border: "2px solid white",
        "@media screen and (max-width: 830px)" : {
            width: "100%",
        }
    },
    titre:{
        color: "white",
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "4em",
        fontWeight: "100",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        textShadow: "2px 2px black",
        "@media screen and (max-width: 830px)" : {
            fontSize: "2.5em",
        }
    }
});

const ItemListTissu = (props) => {
    const classes=StyleTest();
    return(
        <div>
            <CardMedia
                className={classes.div}
                image={require( `../ressources/tissu/${ props.img}` )}
            >
                <Typography className={classes.titre}>
                    {props.nom}
                </Typography>
            </CardMedia>
        </div>
    );
}
export default ItemListTissu;
