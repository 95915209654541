import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  menu:{
    textAlign: "center !important",
    color: "white",
    transition: "font-size 0.5s",
    "&:hover": {
        textDecoration: "none",
        fontSize:"0.9rem !important",
        backgroundColor: "transparent",
        color: "white",
    },
  },

  texte: {
    textAlign: "center !important",
    color: "white",
    "&:hover": {
        textDecoration: "none",
        backgroundColor: "white",
        color: "#3f51b5",
    },
  },
});

export default function DropDownDrawer(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const options = props.options;
    const nom = props.nom;
    
    const handleClick = () => {
        setOpen(!open);
    };
    
    const items=[];
    for (let i = 0; i < options.length; i++){
        const media=(
            <Link key={options[i]} href={`/${options[i].split(' ').join('-')}`} className={classes.menu}>
                <ListItem button >
                    <ListItemText primary={options[i]} className={classes.menu}/>
                </ListItem>
            </Link>
        )
        items.push(media);
    }
    
    
    return(
        <div>
            <ListItem className={classes.texte} button onClick={handleClick}>
              <ListItemText primary={nom}/>
              {open ? <ExpandLess style={{position:"absolute"}} /> : <ExpandMore  style={{position:"absolute"}} />}
            </ListItem>
            <Collapse className={classes.menu} in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {items}
              </List>
            </Collapse>
        </div>
    )
}