import React from 'react';
import Link from "@material-ui/core/Link"
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {makeStyles} from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const StyleTest = makeStyles({
    couleur: {
      color: "white",
      border: "none",
      transition: "font-size 0.5s",
      "&:hover": {
        backgroundColor: "white",
        color: "#3f51b5",
      },
    },
    bordure: {
      boxShadow: "none",
      visibility: "visible",
    },
    link: {
      "&:hover": {
        textDecoration: "none",
      },
      color: "black",
    },
    boutons: {
      width: "inherit",
      marginRight: "10px",
      alignSelf: "center",
    }
});

export default function DropDown(props) {
  const classes = StyleTest();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const options = props.options;
  const nom = props.nom;

  

  const handleMenuItemClick = (event, index,option) => {
    setSelectedIndex(index);
    setOpen(false);
    option=option.split(' ').join('-');
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid className={classes.boutons} container direction="column">
      <Grid item xs={12} >
      <div onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" className={classes.bordure} >
          
          <Button
            className={classes.couleur}
            size="small"
            variant="text"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
          >{nom}
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener  onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <Link key={index} href={`/${option.split(' ').join('-')}`} className={classes.link} >
                      <MenuItem
                        key={index}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index, option)}
                      >
                        {option}
                      </MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </div>
      </Grid>
    </Grid>
  );
}