import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MediaCard from './MediaCard';
import reportage from './ressources/Reportage.mp4';
import reportage2 from './ressources/Reportage.avi';
import reportage3 from './ressources/Reportage.ogg';


const StyleTest = makeStyles({
    titre: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        background: "linear-gradient(to left, #f44336,#f44336)",
        height: "300px",
        transform: "rotate(-15deg)",
        width: "800px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        color: "white",
        fontSize: "5em",
        fontWeight: "100",
        marginTop:"-70px",
        marginLeft:"-30px",
        transition: "width 1s",
        "@media screen and (max-width: 830px)" : {
            marginLeft:0,
            transform: "none",
            marginTop:"-150px",
            width: "100% !important",
            fontSize: "2em !important",
        },
        "@media screen and (max-width: 1200px)" : {
            width: "600px",
            fontSize: "3em",
        },
    },
    mediaCard:{
        background: "none",
        marginTop:"50px",
        boxShadow: "none",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    titreTexte:{
        marginBottom: "50px",
        textAlign: "center",
    },
    video:{
        "@media screen and (max-width: 830px)" : {
            width: "100%",
        }
    },
    container:{
        justifyContent: "center",
        width: "auto",
        "@media screen and (max-width: 830px)" : {
            flexDirection: "column",
        }
    },
    containerItem:{
        flexBasis: "0px",
        maxWidth: "none",
        margin: "0px 20px",
    },
    image: {
      width: "35%",
      "@media screen and (max-width: 830px)" : {
        width: "90%",
      }
    },
    titreJournal: {
        textAlign: "center",
        paddingTop: "50px",
        "@media screen and (max-width: 830px)": {
            paddingTop: "10px !important",
            fontSize: "0.70rem",
        },
    },
    texteJournal:{        
        width: "28%",
        fontSize: "0.85rem",
        paddingTop: "25px",
        textAlign: "justify",
        lineHeight: "1.2",
        paddingRight: "8px",
        paddingLeft: "2px",
        "@media screen and (max-width: 830px)": {
            width: "100% !important",
            paddingTop: "10px !important",
            fontSize: "0.60rem",
        },
    },
    texteJournal2:{
        width: "26%",
        fontSize: "0.78rem",
        textAlign: "justify",
        lineHeight: "1.2",
        paddingRight: "8px",
        paddingLeft: "2px",
        paddingTop: "0px",
        "@media screen and (max-width: 830px)": {
            width: "100% !important",
            fontSize: "0.60rem",
        },
    },
    texteJournal3:{
        fontSize: "0.80rem",
        paddingTop: "142px",
        textAlign: "right",
        lineHeight: "1.2",
        paddingRight: "8px",
        paddingLeft: "23%",
        fontWeight: "bold",
        "@media screen and (max-width: 830px)": {
            width: "100% !important",
            paddingTop: "10px !important",
            fontSize: "0.60rem",
            marginTop: "0px !important",
            paddingLeft: "15% !important",
        },
    },
    divJournal:{
        display: "flex",
        "@media screen and (max-width: 830px)": {
            display : "block",
        },
    },
    cliqueImage:{
        visibility: "hidden",
        textAlign: "center",
        "@media screen and (max-width: 830px)": {
            visibility : "visible",
        },
    }
});

const Medias = () => {
    const classes=StyleTest();
    return(
        <div>
            <ScrollAnimation animateIn="rotateInDownLeft" animateOnce={true}>
                <div className={classes.titre}>
                    Médias
                </div>
            </ScrollAnimation>
            <div className={classes.mediaCard}>
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                    <Typography component="h2" variant="h2" className={classes.titreTexte}>
                        Interviews
                    </Typography>
                </ScrollAnimation>
                <Grid container spacing={3} className={classes.container}>
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <Grid item xs={6} className={classes.containerItem}>
                            <MediaCard lien="/interview3.mp3" nom="Interview 1" date="30 Avril 2018" source="https://www.francebleu.fr/emissions/la-saga-france-bleu-saint-etienne-loire/saint-etienne-loire/la-saga-du-tissage-des-roziers"/>
                        </Grid>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <Grid item xs={6} className={classes.containerItem}>
                            <MediaCard lien="/interview4.mp3" nom="Interview 2" date="1 Mai 2018" source="https://www.francebleu.fr/emissions/la-saga-france-bleu-saint-etienne-loire/saint-etienne-loire/la-saga-fbsel-79"/>
                        </Grid>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <Grid item xs={6} className={classes.containerItem}>
                            <MediaCard lien="/interview2.mp3" nom="Interview 3" date="2 Mai 2018" source="https://www.francebleu.fr/emissions/la-saga-france-bleu-saint-etienne-loire/saint-etienne-loire/la-saga-fbsel-80"/>
                        </Grid>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <Grid item xs={6} className={classes.containerItem}>
                            <MediaCard lien="/interview1.mp3" nom="Interview 4" date="3 Mai 2018" source="https://www.francebleu.fr/emissions/la-saga-france-bleu-saint-etienne-loire/saint-etienne-loire/la-saga-fbsel-81"/>
                        </Grid>
                    </ScrollAnimation>
                </Grid>
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                    <Typography component="h2" variant="h2" className={classes.titreTexte}>
                        Reportages
                    </Typography>
                    <video width="100%" controls>
                        <source src={reportage} type="video/mp4"/>
                        <source src={reportage2} type="video/avi"/>
                        <source src={reportage3} type="video/ogg"/>
                    </video>
                    <Typography variant="subtitle1" color="textSecondary" style={{marginLeft: "5px"}}>
                        Source : <a rel="noopener noreferrer" target="_blank" href="https://www.dailymotion.com/video/x6uejgp">DailyMotion</a>
                    </Typography>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                    <Typography component="h2" variant="h2" className={classes.titreTexte}>
                        Journaux
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.cliqueImage}>
                        Cliquez sur l'image pour voir le texte
                    </Typography>
                    <div className="ImageJournal">
                        <p className="texteImageJournal">
                            <Typography component="h4" variant="h4" className={classes.titreJournal}>
                                Un atelier de tissage fournit la haute couture nationale et internationale
                            </Typography>
                            <Typography variant="body1" gutterBottom className={classes.texteJournal}>
                                L'atelier les Tissage de Rozier situé rue des Canuts à Rozier-en-donzy a fait du tissage en velours jacquard sa spécialité depuis 1925 et a faut vivre rois génération de tisserands. Ajourd'hui, le petit atelier roziérois dirigé par Emmanuel Periat est en pleine expansion et fournit les plus grands créateurs
                            </Typography>
                            <Typography variant="body1" gutterBottom className={classes.texteJournal3}>
                                De gauche à droite Julien Tereins, Sandra Dorbaine (styliste), Christine Cheval et Emmanuel Periat (chef d'entreprise)
                            </Typography>
                            <div className={classes.divJournal}>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2}>
                                    Avec un million d'euros de chiffre d'affaires par an, l'Atelier des Tisserands ne connaît pas la crise. Emmanuel Periat a repris la petite entreprise en 2007 dans le but de développer l'activité tout en gardant les clients fidèles depuis des générations. Et pour cause ! Les clients qui traitent avec l'atelier sont reconnus à l'international puisqu'il s'agit de créateurs de haute couture français ou italiens par exemple. <b>Trois cent cinquante dessins sont imaginés chaque année.</b> Dix personnes travaillent donc avec passion pour allier performance et authenticité au quotidien
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2}>
                                    «La créativité et la réactivité sont nos forces», assure Emmanuel Periat. Ainsi, trois cent cinquante dessins sont imaginés chaque année par Sandra Dorbaine, la styliste de l'atelier qui propose deux collections à ses clients. Mais la création reste une petite partie de l'activité de la société. En effet, l'atelier traite davantage en tissage «converting» c'est-à-dire qu'à partir d'un dessin soumis par le client, les tisserands vont fournir la matière et fabriquer un tissu. Et quel tissu ! La production finale est d'une finesse incontestable, le travail colossal, 10 mètres
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2}>
                                    de tissu par jour seulement. «Nous travaillons uniquement à la commande, nous n'avons pas de stock, le but c'est d'être à l'écoute du client.» <b>Entre tradition et innovation</b> Le velour jacquard sur fond mousseline représente plus de 20% du chiffre d'affaire de l'atelier. «On tisse en double nappe et on produit des articles très rares sur de vieux métiers qui datent de plus d'une centaine d'années», explique le chef d'entreprise. Si l'authenticité
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2}>
                                    est une préoccupation incessant pour l'atelier, il n'en demeure pas moins que des projet innovants sont en préparation. «Je veux garder une branche haute couture et en parallèle développer le tissu décoratif et le tissu technique». En ce moment, l'atelier étudie un projet pour le moins inédit. En effet un tissu technique, pour ne pas dire technologique, est à l'étude, il s'agit d'un velours 100% fil de verre pour filtrer les eaux usées.«L'industrie ne m'intéresse pas, je souhaite créer de nouvelles choses et m'inscrire dans un marché de niche qui fera la valeur de la société.» 
                                </Typography>
                            </div>
                        </p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.cliqueImage}>
                        Cliquez sur l'image pour voir le texte
                    </Typography>
                    <div className={["ImageJournal","ImageJournal2"].join(' ')} style={{width:"600px"}}>
                        <p className={["texteImageJournal","texteImageJournal2"].join(' ')}>
                            <Typography component="h4" variant="h4" className={classes.titreJournal} style={{paddingTop:"100px"}}>
                                Un incroyable savoir-faire de tisseur se cache derrière les murs
                            </Typography>
                            <Typography variant="body1" gutterBottom className={classes.texteJournal} style={{width:"35%"}}>
                                En visite dans l'entreprise Tissage des Rozier, Marianne Darfeuille et Pierre Véricel, les deux conseillers départementaux du canton de Feurs ont découvert des techniques de tissage qui embrassent de nombreux domaines. Difficile de penser que derrière une simple porte de la rue des Canuts à Rozier-en-Donzy, se cache un savoir-faire recherché par les plus grands couturiers. C'est le constat qu'on fait les deux conseillers départementaux du canton de Feurs alors qu'ils s'entretenaient avec Emmanuel Perriat, le PDG de l'entreprise Tissage des Roziers. 
                            </Typography>
                            <Typography variant="body1" gutterBottom className={classes.texteJournal3} style={{paddingTop: "0px",marginTop: "-50px",paddingLeft: "35%"}}>
                                Emmanuel Perriat (au centre) explique à Marianne Darfeuille et Pierre Véricel (à droite) les subtilités des métiers à tisser sous les yeux de Didier Berne (à gauche), le maire de Rozier-en-Donzy.
                            </Typography>
                            <div className={classes.divJournal}>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2} style={{width: "33%"}}>
                                    <b>Il faut deux ans pour former un bon tisseur sur velours</b> Dans une activité emblématique de la région mais qui a connu tin nombre important de bouleversements depuis les années 1980, l'entreprise roziéroise est parvenue à tirer son épingle du jeu en se spécialisant sur des marchés de niche. « Nous avons aujourd'hui dix-huit métiers dans l'atelier, précisait Emmanuel Perriat. Dont certains que nous avons adaptés pour dé-velopper des tissus à consonance
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2} style={{width: "33%"}}>
                                    technique. » On peut citer entre autres, ceux qui servent à habiller les skis ou ceux qui pourraient jouer un rôle considérable dans la filtration des eaux usées ou de l'air pollué. Mais la grande spécialité de l'entreprise tient toujours dans la production de velours jacquard. « Nous ne sommes que deux en France, voire en Europe, à connaître ce savoir-faire exigeant, affirmait le PDG. » D'où sa démarche singulière de n'employer que des habitants de la commune qu'il forme sur place à ces techniques. « Il 
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.texteJournal2} style={{width: "33%"}}>
                                    faut deux ans pour former un bon tisseur sur velours. Nous sommes dix dans l'entreprise tous motivés, habiles, soigneux et patients. » <b>Une collaboration avec tes grands couturiers</b> Aujourd'hui, Tissage du Rozier travaille avec nombre de grands couturiers, mais également avec des entreprises qui ont besoin de velours jacquard pour leur activité. Une réussite que Marianne Darfeuille et Pierre Véricel n'ont pas manqué de souligner.
                                </Typography>
                            </div>
                        </p>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}
export default Medias;
