import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ItemListTissu from './Tissu/ItemListTissu';
import {ItemListTissuHauteCouture} from '../variables.js';
import {ItemListTissuVelours} from '../variables.js';
import Link from "@material-ui/core/Link";
import CardMedia from "@material-ui/core/CardMedia";
import ScrollAnimation from 'react-animate-on-scroll';
import schema1 from './ressources/schema1.jpg';
import schema2 from './ressources/schema2.jpg';

const StyleTest = makeStyles({
    div: {
        marginTop: "66px",
    },
    divScroll:{
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
    }
});

const ListTissuHauteCouture = (props) => {
    const classes=StyleTest();
    let list=[];
    let items=[];
        if(props.match.path === "/Haute-couture"){
            list=ItemListTissuHauteCouture;
        }
        else{
            list=ItemListTissuVelours;
            let media=(
                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                    <p style={{
                        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "1.5em",
                        fontWeight: "100",
                        color: "black",
                        textAlign:"center",
                        marginBottom: "0px",
                        paddingTop: "20px"}}>
                        Le velours que nous créons se décompose de la façon suivante :
                    </p>
                    <CardMedia
                        image={schema1}
                        component="img"
                        style={{padding:"30px 5%"}}
                   />
                   <CardMedia
                        image={schema2}
                        component="img"
                        style={{padding:"30px 5%"}}
                   />
                </ScrollAnimation>
            );
            items.push(media);
        }
    return(
        <div className={classes.div}>
            {items}
                {list.map((item) => (
                    <ScrollAnimation className={classes.divScroll} animateIn="slideInUp" key={item.Id} animateOnce={true}>
                        <Link href={`/Tissus${props.match.path}/${item.Id}`}>
                            <ItemListTissu img={item.BGImage} nom={item.NomTissu}/>
                        </Link>
                    </ScrollAnimation>
                ))}
        </div>
    );
}
export default ListTissuHauteCouture;
