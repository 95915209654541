//Menu
const Menu = [];
Menu[0] = [["Notre entreprise"],["Présentation","Notre savoir-faire"]];
Menu[1]=[["Tissus"],["Haute couture","Techniques","Velours"]];
Menu[2]="Médias";
Menu[3]="Contact";
export {Menu};

const Histoire= [];
Histoire[0]=["2018","Rideau thermique","Création d'un tissu pour rideau thermique"];
Histoire[1]=["2017","Velvers","Création de Velvers, un velours 100% verre pour le traitement de l'eau et de l'air"];
Histoire[2]=["2014","Tissus techniques","Tissage des Roziers se lance dans la création de tissus techniques"];
Histoire[3]=["2010","Technico-décoratif","Création de nouveaux tissus Technico-décoratifs"];
Histoire[4]=["2007","Label EPV","Emmanuel Périat rentre dans l'entreprise pour étoffer l'équipe dirigeante. Dépôt d'un dossier et obtention du label Entreprise du patrimoine vivant"];
Histoire[5]=["1999","Nouveaux locaux","Réorganisation des locaux acquisition de nouveaux métiers tout éléctronique"];
Histoire[6]=["1995","Tissu haut de gamme","1ère mécanique Jacquard éléctronique. Début de commercialisation directe de tissus haut de gamme à l'exportation"];
Histoire[7]=["1989","Création de Tissage des Roziers","Création de la SA (Société Anonyme) Tissage des Roziers."];
Histoire[8]=["1982","Agrandissement des locaux","Agrandissement des locaux et achats de métiers sans navette."];
Histoire[9]=["1975","Reprise de l'entreprise","Gérard Compigne, Le fils de Jean, reprend l'entreprise. Installation des 1er métiers de Velours Jacquard. Transformation en SARL (Société à responsabilité limitée)"];
Histoire[10]=["1950","Reprise de la société","Reprise de la sociéte par Jean et Joseph Compigne"];
Histoire[11]=["1925","Premier tissage","Création du premier tissage par Etienne Compigne. Tissage à Façon d'articles soieries façonnés sur métier Jacquard"];
export {Histoire};

const ListTissuTechAccueil=[];
ListTissuTechAccueil[0]= ["Tissu 1","Description du tissu tech 1","./ressources/tissu.jpg"];
ListTissuTechAccueil[1]= ["Tissu 2","Description du tissu tech 2","./ressources/tissu.jpg"];
export {ListTissuTechAccueil};

const ListTissuAccueil=[];
ListTissuAccueil[0]= ["ressources/couture.jpg","Nos tissus Haute Couture","Tous nos métiers à tisser permettent de travailler une multitude de fils et fibres textiles et de réaliser d’innombrables armures, dessins et tissus jacquards lancé découpé, tissu poche, faux unis, gaze, jacquard soie teint en pièces, jacquard teint en fils, jacquard laqué, faux unis laqués. Tissage des Roziers réalise aujourd’hui vos demandes de tissus, dessins et armures sur mesure.","/Haute-couture","Voir nos tissus Haute Couture"];
ListTissuAccueil[1]= ["ressources/velourVerre.jpg","Nos tissus Techniques","Tissage des Roziers a de nombreux projets collaboratifs nationaux qui favorise le développement de nouveaux textiles techniques qui s’appuient sur notre savoir faire : le velours et le tissu lamé. Fort de cette base nous élaborons des tissus innovants dans le domaine de la filtration, de la dépollution, de la protection solaire et de l’isolation. Ainsi nous pouvons proposer à nos clients un produit de qualité garanti Made in France.","/Techniques","Voir nos tissus techniques"];
ListTissuAccueil[2]= ["ressources/velour.jpg","Nos tissus Velours","Nos velours jacquard sont tissés sur des métiers à navettes Béridot équipés de mécaniques jacquard électronique. C’est un velours double pièce qui est séparé au fur et à mesure du tissage par un rasoir Nous maitrisons toute la chaine de fabrication. A savoir la conception au dessin, le tissage, le rasage et le déflottage du tissu, la teinture ou le peint main ainsi que le pannage du poil. Nous avons aussi la possibilité de réaliser du poil droit, ce qui fait de notre produit un tissu 100 % made in France.","/Velours","Voir nos tissus velours"];
export {ListTissuAccueil};

const itemsCarousel = [
  {
      Id: "Masques",
      Lien: "/wordpress",
      Name: "Masque",
      Caption: "Acheter des masques dès maintenant",
      contentPosition: "middle",
      Message: "Acheter maintenant",
      Items: [
          {
              Id: "ImageMasque",
      	      Lien: "/wordpress",
              Name: "Masque en tissu",
              Image: "ressources/imageMasque1.jpg",
          },
          {
              Id: "ImageTissu",
              Lien: "/wordpress",
              Name: "Tissu fabriqué en France",
              Image: "ressources/imageMasque2.jpg",
          }
      ]
  },
  /*{
      Id: "Contruction",
      Lien: "/",
      Name: "Construction",
      Caption: "Site internet en construction",
      contentPosition: "middle",
      Message: "Page d'accueil",
      Items: [
          {
              Id: "ImageContruction",
      	      Lien: "/",
              Name: "Site en construction",
              Image: "ressources/construction.png",
          },
          {
              Id: "ImageChantier",
              Lien: "/",
              Name: "Site en Construction",
              Image: "ressources/chantier.jpg",
          }
      ]
  }*/
];
export {itemsCarousel};

const ItemListTissuHauteCouture = [{Id: "Tissu_1",NomTissu: "Ottoman",Description: "Ottoman uni",BGImage: "haute1A.jpg",Photos: ["haute1A.jpg","haute1B.jpg"]},
  {Id: "Tissu_2",NomTissu: "Unis",Description: "Unis dégradés et froissé base lurex",BGImage: "haute2A.jpg",Photos: ["haute2A.jpg","haute2B.jpg"]},
  {Id: "Tissu_3",NomTissu: "Tissu gaze",Description: "Tissu gaze trame lurex froissé bouillonné",BGImage: "haute3A.jpg",Photos: ["haute3A.jpg","haute3B.jpg","haute3C.jpg"]},
  {Id: "Tissu_4",NomTissu: "Tissu gaze",Description: "Tissu gaze trame lurex froissé et injurré",BGImage: "haute4A.jpg",Photos: ["haute4A.jpg","haute4B.jpg"]},
  {Id: "Tissu_5",NomTissu: "Double face",Description: "Double face lamé retracté",BGImage: "haute5A.jpg",Photos: ["haute5A.jpg","haute5B.jpg","haute5C.jpg"]},
  {Id: "Tissu_6",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece",BGImage: "haute6A.jpg",Photos: ["haute6A.jpg","haute6B.jpg"]},
  {Id: "Tissu_7",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece",BGImage: "haute7A.jpg",Photos: ["haute7A.jpg","haute7B.jpg","haute7C.jpg","haute7D.jpg"]},
  {Id: "Tissu_8",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece",BGImage: "haute8A.jpg",Photos: ["haute8A.jpg","haute8B.jpg","haute8C.jpg","haute8D.jpg"]},
  {Id: "Tissu_9",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece sur base organza",BGImage: "haute9A.jpg",Photos: ["haute9A.jpg","haute9B.jpg","haute9C.jpg","haute9D.jpg","haute9E.jpg"]},
  {Id: "Tissu_10",NomTissu: "Jacquard peau de bête",Description: "Jacquard peau de bete laqué base acétate",BGImage: "haute10A.jpg",Photos: ["haute10A.jpg","haute10B.jpg","haute10C.jpg"]},
  {Id: "Tissu_11",NomTissu: "Jacquard tweed",Description: "Jacquard tweed lamé",BGImage: "haute11A.jpg",Photos: ["haute11A.jpg","haute11B.jpg"]},
  {Id: "Tissu_12",NomTissu: "Jacquard tweed",Description: "Jacquard tweed lamé coton et viscose",BGImage: "haute12A.jpg",Photos: ["haute12A.jpg","haute12B.jpg","haute12C.jpg"]},
  {Id: "Tissu_13",NomTissu: "Mousseline",Description: "Mousseline lamée retractée changeante soie et lurex",BGImage: "haute13A.jpg",Photos: ["haute13A.jpg","haute13B.jpg","haute13C.jpg"]},
  {Id: "Tissu_14",NomTissu: "Uni",Description: "Uni changeant lurex rond",BGImage: "haute14A.jpg",Photos: ["haute14A.jpg","haute14B.jpg"]},
  {Id: "Tissu_15",NomTissu: "Jacquard peau de bête",Description: "Jacquard peau de bête laqué / Acetate",BGImage: "haute15A.jpg",Photos: ["haute15A.jpg","haute15B.jpg","haute15C.jpg","haute15D.jpg","haute15E.jpg","haute15F.jpg"]},
  {Id: "Tissu_16",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé ombre soie et lurex",BGImage: "haute16A.jpg",Photos: ["haute16A.jpg","haute16B.jpg"]},
];
export {ItemListTissuHauteCouture};

const ItemListTissuVelours = [{Id: "Tissu_1",NomTissu: "Ottoman",Description: "Ottoman uni",BGImage: "velour1D.jpg",Photos: ["velour1A.jpg","velour1B.jpg","velour1C.jpg","velour1D.jpg"]},
  {Id: "Tissu_2",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece",BGImage: "velour2A.jpg",Photos: ["velour2A.jpg","velour2B.jpg","velour2C.jpg","velour2D.jpg","velour2E.jpg"]},
  {Id: "Tissu_3",NomTissu: "Jacquard tweed",Description: "Jacquard tweed lamé",BGImage: "velour3A.jpg",Photos: ["velour3A.jpg","velour3B.jpg","velour3C.jpg","velour3D.jpg"]},
  {Id: "Tissu_4",NomTissu: "Unis",Description: "Unis changeant lurex rond",BGImage: "velour4D.jpg",Photos: ["velour4A.jpg","velour4B.jpg","velour4C.jpg","velour4D.jpg","velour4E.jpg","velour4F.jpg"]},
  {Id: "Tissu_5",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé ombre soie et lurex",BGImage: "velour5A.jpg",Photos: ["velour5A.jpg","velour5B.jpg","velour5C.jpg","velour5D.jpg"]},
  {Id: "Tissu_6",NomTissu: "Double face",Description: "Double face lamé retracté",BGImage: "velour6A.jpg",Photos: ["velour6A.jpg","velour6B.jpg","velour6C.jpg","velour6D.jpg"]},
  {Id: "Tissu_7",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece",BGImage: "velour7A.jpg",Photos: ["velour7A.jpg","velour7B.jpg","velour7C.jpg","velour7D.jpg","velour7E.jpg","velour7F.jpg"]},
  {Id: "Tissu_8",NomTissu: "Mousseline",Description: "Mousseline lamé retractée changeante soie et lurex",BGImage: "velour8A.jpg",Photos: ["velour8A.jpg","velour8B.jpg","velour8C.jpg","velour8D.jpg"]},
  {Id: "Tissu_9",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé teinté piece",BGImage: "velour9A.jpg",Photos: ["velour9A.jpg","velour9B.jpg","velour9C.jpg","velour9D.jpg","velour9E.jpg","velour9F.jpg"]},
  {Id: "Tissu_10",NomTissu: "Unis",Description: "Unis changeant lurex rond",BGImage: "velour10A.jpg",Photos: ["velour10A.jpg","velour10B.jpg","velour10C.jpg","velour10D.jpg"]},
  {Id: "Tissu_11",NomTissu: "Jacquard lamé",Description: "Jacquard lamé lancé découpé ombre soie et lurex",BGImage: "velour11A.jpg",Photos: ["velour11A.jpg","velour11B.jpg","velour11C.jpg"]},
  {Id: "Tissu_12",NomTissu: "Jacquard",Description: "Jacquard solo/viscose – double corps Peint-main",BGImage: "velour12A.jpg",Photos: ["velour12A.jpg","velour12B.jpg","velour12C.jpg"]},
  {Id: "Tissu_13",NomTissu: "Jacquard",Description: "Jacquard – double corps Peint main",BGImage: "velour13A.jpg",Photos: ["velour13A.jpg","velour13B.jpg","velour13C.jpg","velour13D.jpg","velour13E.jpg","velour13F.jpg"]},
];
export {ItemListTissuVelours};