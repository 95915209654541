import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ErrorIcon from '@material-ui/icons/Error';

const StyleTest = makeStyles({
    div: {
        marginTop: "150px",
        textAlign: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    icon:{
        height: "auto",
        width: "25%",
        padding:"50px",
        "@media screen and (max-width: 830px)" : {
            display: "none",
        },
    },
});

const Erreur = () => {
    const classes=StyleTest();
    return(
        <div className={classes.div}>
            <div>
                <Typography variant="h1" gutterBottom>
                    Oups !
                </Typography>
                <br/>
                <Typography variant="h3" gutterBottom>
                    La page que vous recherchez semble introuvable.
                </Typography>
                <br/>
                <br/>
                <Link href="/">
                    <Typography variant="h5" gutterBottom>
                        Retouner a l'accueil
                    </Typography>
                </Link>
                <br/>
                <br/>
            </div>
            <ErrorIcon className={classes.icon}/>
        </div>
    );
}
export default Erreur;
