import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const StyleTest = makeStyles({
    footer: {
        background : "grey",
        marginTop: "50px",
        bottom: 0,
        width: "100%",
    },
    copyright:{
        background: "#606060",
        padding: "15px 0px",
        textAlign: "center",
    }
});

const Footer = () => {
    const classes=StyleTest();
    return(
        <footer>
            <div className={classes.footer}>
                <br/>
                        <Typography variant="h6" align="center" gutterBottom>
                            Tissage des Roziers
                        </Typography>
                        <Link href="/MentionsLegales">
                            <Typography variant="subtitle1" align="center" color="textPrimary" component="p">
                                Mentions légales
                            </Typography>
                        </Link>
                        <Link href="/Contact">
                            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                                Nous Contacter
                            </Typography>
                        </Link>
                <br/>
            </div>
            <div className={classes.copyright}>
                Copyright © 2020 Tissage Des Roziers
            </div>
        </footer>
    );
}
export default Footer;
