import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import imageTissu from './ressources/velour.jpg';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles =  makeStyles({
    containerHaut: {
        fontSize: "1.5em",
        fontWeight: "100",
        transition: "width 1s",
        position: "absolute",
        backgroundColor: "#0E3B85",
        color:"white",
        textAlign: "center",
        padding: "30px",
        alignSelf: "center",
        right: "8%",
        width: "35%",
        "@media screen and (max-width: 830px)" : {
            position: "inherit",
            marginLeft:"0px !important",
            marginRight: "0px",
            padding: "20px",
            width: "100%",
            fontSize: "1em",
            right: "auto",
            marginBottom: "350px",
        },
    },
    titre: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        background: "linear-gradient(to left, #f44336,#f44336)",
        height: "300px",
        transform: "rotate(-15deg)",
        width: "800px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        color: "white",
        fontSize: "5em",
        fontWeight: "100",
        marginTop:"-70px",
        marginLeft:"-30px",
        transition: "width 1s",
        "@media screen and (max-width: 830px)" : {
            marginLeft:0,
            transform: "none",
            marginTop:"-150px",
            width: "100% !important",
            fontSize: "2em !important",
        },
        "@media screen and (max-width: 1200px)" : {
            width: "600px",
            fontSize: "3em",
        },
    },
    divimage: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        paddingRight: "200px",
        "@media screen and (max-width: 830px)" : {
            marginTop: "0px",
            paddingBottom: "0px",
            paddingRight: "0px",
            width: "100%",
        }
    },
    divHaut: {
        display: "flex",
        marginTop:"50px",
        "@media screen and (max-width: 830px)" : {
            marginTop:"0px",
            flexDirection: "column",
        }
    },
    paragraphe: {
        fontFamily: "Segoe UI,Roboto, Helvetica, Arial, sans-serif",
        fontSize: "1.5em",
    },
    hautBackground:{
      background : "linear-gradient(to top right,#f44336 50%,transparent 50%)",
      width: "100%",
      height: "150px",
      marginTop: "-300px",
    },
    milieuBackground:{
      backgroundColor: "#f44336",
      width: "100%",
      height: "200px",
      display: "flex",
      alignItems: "flex-end",
      marginTop: "-1px",
      marginBottom: "-1px",
    },
    basBackground:{
      background : "linear-gradient(to bottom left,#f44336 50%,transparent 50%)",
      width: "100%",
      height: "150px",
    },
    titreContact:{
      fontSize: "1.5em",
    },
    image:{
      width:"800px",
      height:"700px",
      "@media screen and (max-width: 830px)" : {
        width:"100%",
        height:"auto",
      }
    },
    carte:{
      textAlign: "center",
    },
    carte2:{
      "@media screen and (max-width: 830px)" : {
        width:"100%",
        height:"400px",
      }
    },
    titreTexte:{
      padding: "20px 0px",
    }
});


export default function Contact() {
  const classes = useStyles();
  return (
    <div>
        <ScrollAnimation animateIn="rotateInDownLeft" animateOnce={true}>
            <div className={classes.titre}>
                Contact
            </div>
        </ScrollAnimation>
           
        <div className={classes.divHaut}>
            <ScrollAnimation className={classes.divimage} animateIn="slideInUp" animateOnce={true}>
                    <CardMedia
                        image={imageTissu}
                        component="img"
                        className={classes.image}
                   />
            </ScrollAnimation>
            <ScrollAnimation className={classes.containerHaut} animateIn="slideInUp" animateOnce={true}>
                    <p className={classes.paragraphe}>
                        <span className={classes.titreContact}>Nous contacter<br/></span>
                        <span>
                          Tissage des Roziers<br/>
                          270 rue des Canuts<br/>
                          ROZIER-EN-DONZY<br/>
                          42810 - FRANCE<br/>
                          04 77 28 04 87<br/>
                          secretariat@tissage-des-roziers.fr<br/>
                        </span>
                    </p>
            </ScrollAnimation>
        </div>
        
        <div className={classes.hautBackground}/>
        <div className={classes.milieuBackground}/>
        <div className={classes.basBackground}/>
        <ScrollAnimation className={classes.carte} animateIn="slideInUp" animateOnce={true}>
          <Typography component="h2" variant="h2" className={classes.titreTexte}>
            Nous trouver
          </Typography>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.7398770066034!2d4.271400715885241!3d45.79643881923289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4418f4482a491%3A0xbc857473ec3e2c75!2s270%20Rue%20des%20Canuts%2C%2042810%20Rozier-en-Donzy!5e0!3m2!1sen!2sfr!4v1589468921976!5m2!1sen!2sfr" className={classes.carte2} width="700" height="550" title="maps" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </ScrollAnimation>
    </div>
  );
}